import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "src/redux/store";

import Router from "src/views/IndexWeb";
function App() {
  return (
    <div className="App">
      <Provider store={store().store}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
