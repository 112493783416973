import React from "react";
import ReactDOM from "react-dom/client";
import { ApolloProvider } from "@apollo/client";
import ApolloClient from "src/apollo";

import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={ApolloClient}>
    <App />
  </ApolloProvider>
);
