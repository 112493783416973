import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { lazy, Suspense } from "react";

const DashboardRouter = lazy(() => import(`src/views/Dashboard/Router`));
const PublicRouter = lazy(() => import(`src/views/Public/Router`));
const WEB_ROUTES = ["/", "/register", "/login"];
const COMMON_ROUTES = ["/dashboard"];

function IndexWeb() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  let currentPath = location.pathname;
  let web = false;
  let app = true;
  if (WEB_ROUTES.includes(currentPath)) {
    web = true;
    app = false;
  }
  return (
    <>
      <Suspense fallback={<p>Loading</p>}>
        {app && <DashboardRouter />}
        {web && <PublicRouter />}
      </Suspense>
    </>
  );
}

export default IndexWeb;
